<template>
  <div>
    <el-card>
      <div class="topHeader clearfix">
        <div>
          <span class="userName">
            当前登录: <span>{{ userName }}</span>
          </span>
          <div>
            <el-link type="primary" v-if="this.eProcList.length >= 2 && mode == 1" class="retHome" @click="exitHome" :underline="false">
              <i class="el-icon-switch-button"></i> 切换登录</el-link
            >
          </div>
        </div>

        <el-header :class="{ head_read: true }">
          <el-form label-width="160px" ref="readFormRef" :model="readForm" class="readForm">
            <el-form-item prop="rfid_no" label="RFID：" size="samll" :rules="[{ required: true }]">
              <el-input
                v-model="readForm.rfid_no"
                ref="cardRef"
                @input="cardChange"
                :disabled="isDisabled"
                placeholder="请刷卡"
                show-word-limit
                class="readInput"
              >
                <template slot="append">
                  <el-link type="primary" @click="emptyChange" class="empty vg_pointer">清空</el-link>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="样品编号：" size="samll" prop="smpl_no">
              <el-input :disabled="!isStpr" v-model="readForm.smpl_no" class="readInput">
                <template slot="append">
                  <el-link type="primary" @click="smplNoSearch" class="empty vg_pointer">确定</el-link>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="样品数量：" size="samll" prop="smpl_num">
              <el-input :disabled="true" v-model="readForm.smpl_num" class="readInput"></el-input>
            </el-form-item>
            <el-form-item label="委托单号：" size="samll" prop="requ_no">
              <el-input :disabled="true" v-model="readForm.requ_no" class="readInput"></el-input>
            </el-form-item>
            <el-form-item label="工序：" size="samll" prop="defa_proc">
              <el-select
                v-model="readForm.defa_proc"
                @change="seChange"
                placeholder="请选择"
                popper-class="selectRead"
                :disabled="mode == 1"
              >
                <el-option v-for="item in proc_listc" :key="item.key" :label="item.label" :value="item.key"> </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-header>
        <div>
          <el-link type="primary" class="retHome" v-if="mode == 1" @click="returnHome" :underline="false">
            <i class="el-icon-d-arrow-left"></i> 返回首页</el-link
          >
        </div>
      </div>
      <el-main>
        <op-edi v-if="readForm.defa_proc == 'open'" :prop="prop" @empty="empty" />
        <stpr v-if="readForm.defa_proc == 'stpr'" :prop="prop" @empty="empty" @smplNoSearch="smplNoSearch" @outSuccess="outSuccess" />
        <mase v-if="readForm.defa_proc == 'mase'" :prop="prop" @empty="empty" />
        <inspec v-if="readForm.defa_proc == 'insp'" :prop="prop" :SmplId="SmplId" @empty="empty" />
        <manual v-if="readForm.defa_proc == 'manu'" :prop="prop" @empty="empty" />
        <embr v-if="readForm.defa_proc == 'embr'" :prop="prop" @empty="empty" />
        <lase v-if="readForm.defa_proc == 'lase'" :prop="prop" @empty="empty" />
        <comp v-if="readForm.defa_proc == 'comp'" :prop="prop" @empty="empty" />
        <stam v-if="readForm.defa_proc == 'stam'" :prop="prop" @empty="empty" />
        <emcd v-if="readForm.defa_proc == 'emcd'" :prop="prop" @empty="empty" />
        <hand v-if="readForm.defa_proc == 'handCut'" :prop="prop" @empty="empty" />
      </el-main>
    </el-card>
  </div>
</template>

<script>
import { get } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
import { procAPI } from '@api/modules/proc';
import OpEdi from '@/views/OperateManagement/ReadManage/TabChild/OpEdi.vue';
import Stpr from '@/views/OperateManagement/ReadManage/TabChild/Stpr.vue';
import Mase from '@/views/OperateManagement/ReadManage/TabChild/Mase.vue';
import Inspec from '@/views/OperateManagement/ReadManage/TabChild/Inspec.vue';
import Manual from '@/views/OperateManagement/ReadManage/TabChild/Manual.vue';
import Embr from '@/views/OperateManagement/ReadManage/TabChild/Embr.vue';
import Lase from '@/views/OperateManagement/ReadManage/TabChild/Lase.vue';
import Comp from '@/views/OperateManagement/ReadManage/TabChild/Comp.vue';
import Stam from '@/views/OperateManagement/ReadManage/TabChild/Stam.vue';
import Emcd from '@/views/OperateManagement/ReadManage/TabChild/Emcd.vue';
import hand from '@/views/OperateManagement/ReadManage/TabChild/hand.vue';

export default {
  name: 'readCard',
  components: {
    OpEdi,
    Stpr,
    Mase,
    Inspec,
    Manual,
    Embr,
    Lase,
    Comp,
    Stam,
    Emcd,
    hand
  },
  watch: {
    'readForm.defa_proc': function (newValue, oldValue) {
      this.isStpr = newValue === 'stpr';
    }
  },
  data() {
    return {
      isStpr: false,
      readForm: {
        rfid_no: '',
        smpl_no: '',
        requ_no: '',
        defa_proc: ''
      },
      procList: [
        { key: 'manu', label: '手工' },
        { key: 'insp', label: '检验' },
        { key: 'mase', label: '机缝' },
        { key: 'embr', label: '电绣' },
        { key: 'stam', label: '印花' },
        { key: 'lase', label: '激光' },
        { key: 'handCut', label: '手裁' },
        { key: 'comp', label: '复合' },
        { key: 'open', label: '开版' },
        { key: 'stpr', label: '备料' },
        { key: 'emcd', label: '制卡' }
      ],
      procForm: {},
      // 员工拥有的所有工序
      proc_listc: [],
      eProcList: [],
      prop: {
        user_id: '',
        stff_id: '',
        stff_name: '',
        open_status: '',
        rfid_id: '',
        user_rfid_no: '',
        mode: null,
        smpl_id: null
      },
      prop2: {},
      stprProp: {},
      isDisabled: false,
      userName: '',
      SmplId: null
    };
  },
  methods: {
    // 根据样品编号获取RFID并查询
    smplNoSearch() {
      this.readForm.smpl_no = this.readForm.smpl_no.trim();
      get(rfidAPI.get_rfid_by_smpl_no, { smpl_no: this.readForm.smpl_no })
        .then(res => {
          if (res.data.code === 0) {
            this.readForm.rfid_no = res.data.data;
            this.cardChange(this.readForm.rfid_no);
          } else {
            this.$message({ message: res.data.msg, type: 'error' });
          }
        })
        .catch(res => {
          this.$message({ message: res, type: 'error' });
        });
    },
    seChange(val) {
      this.readForm.defa_proc = val;
      if (this.readForm.rfid_no !== '') {
        this.procForm.proc_defa = val;
        this.getData(this.readForm.defa_proc);
      } else {
        this.$nextTick(() => {
          this.$refs.cardRef.focus();
        });
      }
    },
    getProcForm() {
      get(procAPI.get_user_proc)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.warning('请添加员工工序');
          }
          this.procForm = res.data.data.form;

          const proc_codes = this.procForm.proc_codes.split(',');
          for (const i in this.procList) {
            for (const j in proc_codes) {
              if (this.procList[i].key == proc_codes[j]) {
                this.proc_listc.push(this.procList[i]);
              }
            }
          }
          this.proc_listc.forEach(item => {
            this.eProcList.push(item.key);
          });
          if (this.$cookies.get('userMode').type == 0) {
            this.readForm.defa_proc = this.procForm.proc_defa;
          }
        })
        .catch(res => {
          const mg = res.data.msg;
          const tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    getCookie() {
      const userinfo = this.$cookies.get('userInfo');
      const mode = this.$cookies.get('userMode');
      this.mode = mode.type;

      this.prop.user_id = userinfo.user_id;
      this.prop.stff_id = userinfo.stff_id;
      this.prop.stff_name = userinfo.stff_name;
      this.prop.user_rfid_no = userinfo.rfid_no;
      this.prop.user_jobs = userinfo.user_jobs;
    },
    cardChange(val) {
      const trimValue = val.trim();
      if (trimValue.length === 10) {
        this.readForm.rfid_no = trimValue;
        this.getData(trimValue);
        this.isDisabled = true;
      }
    },
    getData(val) {
      const form = {
        rfid_no: this.readForm.rfid_no,
        proc_code: this.readForm.defa_proc
      };
      get(rfidAPI.read_card, form)
        .then(res => {
          if (res.data.code === 0) {
            this.SmplId = res.data.data.rfid.smpl_id;
            this.readForm.smpl_no = res.data.data.rfid.smpl_no;
            this.readForm.requ_no = res.data.data.rfid.requ_no;
            this.readForm.smpl_num = res.data.data.rfid.smpl_num;
            this.prop.open_status = res.data.data.rfid.open_status;
            this.prop.rfid_id = res.data.data.rfid.rfid_id;
            this.prop.smpl_id = res.data.data.rfid.smpl_id;
            if (this.readForm.defa_proc === 'insp') {
              this.prop2 = {};
              this.prop2.isImage = res.data.data.insp.isImage;
              this.prop2.isOutMtrl = res.data.data.insp.isOutMtrl;
              this.$EventBus.$emit('inspEmit', this.prop2);
            }
            // 备料
            if (this.readForm.defa_proc === 'stpr') {
              this.stprProp.rfid_stpr_num = res.data.data.rfid.rfid_stpr_num;
              this.stprProp.smpl_num = res.data.data.rfid.smpl_num;
              const stprList = res.data.data.stpr.smpl_mtrl_list;
              this.$EventBus.$emit('stprEmit', this.stprProp, stprList);
            }
            // 开版返回数据
            if (res.data.data.open) {
              if (this.readForm.defa_proc == 'open') {
                this.prop2 = {};
                this.prop2.rfid_open_phr = res.data.data.open.rfid_open_phr;
                this.prop2.rfid_cut_num = res.data.data.open.rfid_cut_num;
                this.prop2.smpl_id = res.data.data.rfid.smpl_id;
                this.prop2.rfid_open_id = res.data.data.open.rfid_open_id;
                this.$EventBus.$emit('openEmit', this.prop2);
              }
            }
            // 复合
            if (res.data.data.comp) {
              if (this.readForm.defa_proc == 'comp') {
                this.prop2 = {};
                this.prop2.rfid_comp_phr = res.data.data.comp.rfid_comp_phr;
                this.$EventBus.$emit('compEmit', this.prop2);
              }
            }

            // 激光
            if (res.data.data.lase) {
              if (this.readForm.defa_proc == 'lase') {
                this.prop2 = {};
                this.prop2.rfid_patch_lase = res.data.data.lase.rfid_patch_lase;
                this.prop2.rfid_lase_phr = res.data.data.lase.rfid_lase_phr;
                this.$EventBus.$emit('laseEmit', this.prop2);
              }
            }

            //新增手裁 2024年10月17日10:22:48
            if (res.data.data.handCut) {
              if (this.readForm.defa_proc == 'handCut') {
                this.prop2 = {};
                this.prop2.rfid_handcut_phr = res.data.data.handCut.rfid_handcut_phr;
                this.prop2.sample_phr = res.data.data.handCut.sample_phr;
                this.prop2.sample_num = res.data.data.handCut.sample_num;
                this.prop2.stff_name = res.data.data.handCut.stff_name;
                this.prop2.rfid_handcut_post = res.data.data.handCut.rfid_handcut_post;
                this.$EventBus.$emit('handCutEmit', this.prop2);
              }
            }

            // 印花
            if (res.data.data.stam) {
              if (this.readForm.defa_proc == 'stam') {
                this.prop2 = {};
                this.prop2.rfid_stam_phr = res.data.data.stam.rfid_stam_phr;
                this.$EventBus.$emit('stamEmit', this.prop2);
              }
            }

            // 电绣
            if (this.readForm.defa_proc == 'embr') {
              this.prop2 = {};
              this.prop2.rfid_embr_time = res.data.data.embr.rfid_embr_phr;
              this.prop2.rfid_patch_num = res.data.data.embr.rfid_patch_num;
              this.$EventBus.$emit('embrEmit', this.prop2);
            }

            // 制卡

            if (res.data.data.emcd) {
              if (this.readForm.defa_proc == 'emcd') {
                this.prop2 = {};
                this.prop2.rfid_embr_time = res.data.data.emcd.rfid_emcd_phr;
                this.prop2.rfid_patch_num = res.data.data.emcd.rfid_patch_num;
                this.prop2.rfid_emcd_num = res.data.data.emcd.rfid_emcd_num;
                this.prop2.rfid_emcd_numb = res.data.data.emcd.rfid_emcd_numb;
                this.$EventBus.$emit('emcdEmit', this.prop2);
              }
            }

            // 手工
            if (res.data.data.manu) {
              if (this.readForm.defa_proc == 'manu') {
                this.prop2 = {};
                this.prop2.cott_phr = res.data.data.manu.cott_phr;

                this.prop2.cutt_phr = res.data.data.manu.cutt_phr;

                this.prop2.nedl_phr = res.data.data.manu.nedl_phr;

                this.prop2.othe_phr = res.data.data.manu.othe_phr;

                this.prop2.cott_phr = res.data.data.manu.cott_phr;

                this.prop2.remark = res.data.data.manu.remark;

                this.prop2.rfid_manu_phr = res.data.data.manu.rfid_manu_phr;
                this.prop2.skin_phr = res.data.data.manu.skin_phr;
                this.prop2.pack_phr = res.data.data.manu.pack_phr;

                this.prop2.smpl_fill_weight = res.data.data.manu.smpl_fill_weight;

                this.prop2.smpl_skin_weight = res.data.data.manu.smpl_skin_weight;
                this.prop2.smpl_weight = res.data.data.manu.smpl_weight;
                this.prop2.sort_phr = res.data.data.manu.sort_phr;
                this.prop2.rfid_sample_indnum = res.data.data.manu.rfid_sample_indnum;

                this.$EventBus.$emit('manuEmit', this.prop2);
              }
            }

            // 机缝
            if (res.data.data.mase) {
              if (this.readForm.defa_proc === 'mase') {
                this.prop2 = {};
                this.prop2.docu_id = res.data.data.mase.docu_id;
                this.prop2.rfid_mase_time = res.data.data.mase.rfid_mase_time;
                this.prop2.remark = res.data.data.mase.remark;
                this.prop2.docu_id = res.data.data.mase.docu_id;
                this.prop2.docu_name = res.data.data.mase.docu_name;
                this.prop2.rfid_cut_num = res.data.data.mase.rfid_cut_num;
                this.prop2.docu_suffix = res.data.data.mase.docu_suffix;
                this.prop2.rfid_sew_slice = res.data.data.mase.rfid_sew_slice;
                this.prop2.rfid_sample_indnum = res.data.data.mase.rfid_sample_indnum;
                this.prop2.rfid_sample_slicenum = res.data.data.mase.rfid_sample_slicenum;
                this.$EventBus.$emit('maseEmit', this.prop2);
              }
            }
          } else {
            if (res.data.code === 999) {
              this.isDisabled = false;
              this.empty();
              return this.$message.warning(res.data.msg);
            }
            if (res.data.code === 2) {
              this.readForm.rfid_no = '';
              this.$nextTick(() => {
                this.$refs.cardRef.focus();
              });
              this.empty();
              return this.$message.warning('该卡未开卡,请先开卡');
            }
            if (res.data.code !== 0) {
              return this.$message.error(res.data.msg);
            }
          }
        })
        .catch(res => {
          const mg = res.data.msg;
          const tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    emptyChange() {
      this.readForm.rfid_no = '';
      this.readForm.smpl_no = '';
      this.readForm.requ_no = '';
      this.prop.open_status = '';
      this.prop.rfid_id = '';
      this.isDisabled = false;
      this.$nextTick(() => {
        this.$refs.cardRef.focus();
      });
      if (this.readForm.defa_proc === 'open') {
        this.$EventBus.$emit('open');
      } else if (this.readForm.defa_proc === 'stpr') {
        this.$EventBus.$emit('stpr');
      } else if (this.readForm.defa_proc === 'comp') {
        this.$EventBus.$emit('comp');
      } else if (this.readForm.defa_proc === 'lase') {
        this.$EventBus.$emit('lase');
      } else if (this.readForm.defa_proc === 'stam') {
        this.$EventBus.$emit('stam');
      } else if (this.readForm.defa_proc === 'embr') {
        this.$EventBus.$emit('embr');
      } else if (this.readForm.defa_proc === 'manu') {
        this.$EventBus.$emit('manu');
      } else if (this.readForm.defa_proc === 'mase') {
        this.$EventBus.$emit('mase');
      } else if (this.readForm.defa_proc === 'insp') {
        this.$EventBus.$emit('insp');
      } else if (this.readForm.defa_proc === 'emcd') {
        this.$EventBus.$emit('emcd');
      } else if (this.readForm.defa_proc === 'handCut') {
        this.$EventBus.$emit('handCut');
      }
    },

    //
    empty() {
      this.readForm.rfid_no = '';
      this.readForm.smpl_no = '';
      this.readForm.requ_no = '';
      this.prop.open_status = '';
      this.prop.rfid_id = '';
      this.isDisabled = false;
      this.$nextTick(() => {
        this.$refs.cardRef.focus();
      });
    },
    outSuccess() {
      this.getData(this.readForm.defa_proc);
    },
    returnHome() {
      this.readForm.rfid_no = '';
      this.readForm.smpl_no = '';
      this.readForm.requ_no = '';
      this.prop.open_status = '';
      this.prop.rfid_id = '';
      this.isDisabled = false;
      this.emptyChange();
      this.jump('dashboard');
    },
    focus() {
      if (!this.$refs.cardRef) {
      } else {
        setTimeout(() => {
          this.$refs.cardRef.focus();
        }, 100);
      }
    },
    exitHome() {
      this.$cookies.remove('userInfo');
      this.$cookies.remove('push');
      this.$cookies.remove('userMode');
      this.jump('login');

      this.$cookies.set('loginType', false, 60 * 60 * 24 * 7);
    }
  },
  mounted() {
    this.$refs.cardRef.focus();
    if (this.$cookies.get('userMode').type == 1) {
      this.readForm.defa_proc = this.$route.query.proc;
    }

    this.$EventBus.$on('openRead', () => {
      setTimeout(() => {
        this.focus();
      }, 500);
    });
    this.userName = this.$cookies.get('userInfo').stff_name;
  },
  created() {
    this.getProcForm();
    this.getCookie();
  }
};
</script>

<style lang="scss">
.retHome {
  float: left;
  height: 85px;
  font-size: 20px;
  text-align: center;
}
.readForm .el-form-item__label {
  font-size: $font-max;
}
.readInput {
  margin-bottom: 10px !important;
  font-size: $font-max;
  height: 50px;
}
.readInput input {
  height: 50px;
}
.clearfix::after {
  content: '';
  clear: both;
  display: table;
}
.topHeader {
  display: flex;
  border-bottom: 2px dotted #cccccc;
}

.head_read {
  width: 75%;
  float: left;
  padding-bottom: 20px;
  height: 300px !important;
}
.readForm {
  width: 75%;
  margin: 0 auto;
  min-width: 500px;
}
.empty {
  font-size: $font-max;
}

.readForm .el-input__inner {
  font-size: $font-max;
  height: 50px;
}

.selectRead li {
  font-size: $font-max;
  height: 60px;
  line-height: 60px;
}
.userName {
  font-size: 20px;
  span {
    font-weight: 700;
    font-size: 28px;
  }
}
</style>
